<script>
  import {mapGetters, mapState} from 'vuex';
  import WSManager from '@/modules/ws_data_module';
  import PriceEventPrototype from '@/components/assignment_components/one_assignment/chat_events/PriceEventPrototype';

  export default {
    extends: PriceEventPrototype,
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userCanRegisterJobs',
        'maxShortNoticeCancellation'
      ]),
      ...mapGetters('OneAssignmentStore', [
        'isSecretaryAssignment'
      ]),
      ...mapState('OneAssignmentStore', {
        chosenDiscussionID: ({currentDiscussionId}) => currentDiscussionId,
        contractInfo: ({jobCallDetails}) => {
          return jobCallDetails && jobCallDetails.contractInfo ? jobCallDetails.contractInfo[0] : {};
        }
      }),
      jobId() { return this.$route.params.id; },
      assignmentInfo() { return this.assignmentObj.info || {}; },
      interDiscussionStatus() { return this.interDiscussion.status || ''; },
      buyerDiscussionStatus() { return this.buyerDiscussion.status || ''; },
      computedMaxShortNoticeCancellation() {
        return this.assignmentPrices.maxDurationOnShortNoticeCancellation || this.maxShortNoticeCancellation;
      },
      lessThanShortNoticeCancellation() {
        return this.$moment(this.assignmentRequirements.startTime)
          .isSameOrBefore(this.$moment().add(this.computedMaxShortNoticeCancellation, 'hours'));
      },
      // FINISH JOB
      finishTime() { return this.assignmentRequirements.finishTime || ''; },
      startTime() { return this.assignmentRequirements.startTime || ''; },
      jobStatus() { return this.assignmentObj.status || ''; },
      isInProgressJob() { return this.jobStatus === 'in_progress'; },
      isAcceptedJob() { return this.jobStatus === 'accepted'; },
      isOldAcceptedJob() {
        return (this.isAcceptedJob || this.isInProgressJob)
          && this.$moment().isAfter(this.$moment(this.finishTime));
      },
      isNormalAcceptedJob() {
        return this.isAcceptedJob && this.$moment().isBefore(this.$moment(this.startTime));
      },
      isNormalInProgressJob() {
        return (this.isAcceptedJob || this.isInProgressJob)
          && this.$moment().isBefore(this.$moment(this.finishTime))
          && this.$moment().isAfter(this.$moment(this.startTime));
      },
      showFinishButton() {
        return this.isNormalInProgressJob
          || this.isOldAcceptedJob
          || (this.isSecretaryAssignment
            && this.isAcceptedJob);
      },
      isShortNoticeWithdraw() {
        const diffMs = new Date(this.assignmentRequirements.startTime) - new Date(); // subtract the current time from the start time order
        const diffHs = diffMs / 1000 / 60 / 60; // сonverting milliseconds to hours
        return diffHs < 48;
      }
    },
    methods: {
      // EARLY FINISH JOB
      finishJob() {
        this.isOldAcceptedJob
          ? this.openFinishOldJobModal()
          : this.openFinishJobModal();
      },
      openFinishOldJobModal() {
        this.$store.commit('InfoModalStore/setInfoModal', {
          text: this.$gettext('Are you sure you wish to finish this assignment?'),
          method: 'manualJobFinish',
          context: this
        });
      },
      manualJobFinish() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/finishJob', this.jobId).then(() => {
          const promiseArr = [
            this.$store.dispatch('OneAssignmentStore/getJobById', this.jobId),
            this.$store.dispatch('OneAssignmentStore/getDiscussions', this.jobId)
          ];
          Promise.all(promiseArr).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          });
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      openFinishJobModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Are you sure this assignment is finished?'),
            text: this.$gettext('By clicking \'Finished\' this assignment will be marked as finished and you will be taken to the feedback page'),
            context: this,
            modalCallback: this.manualJobFinish,
            btnTexts: {
              actionBtnText: this.$gettext('Finished'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      // BUYER CANCEL BUTTON
      catchReject() {
        this.buyerDiscussionStatus === 'applied'
          ? this.openCancelRejectModal()
          : this.openCancelAssignmentModal();
      },
      // BUYER CANCEL ACCEPTED
      openCancelAssignmentModal() {
        const titleTemplate = this.$gettext('You have already confirmed %{n} for this assignment.');
        const titleAccepted = this.$gettextInterpolate(titleTemplate, {n: this.contractInfo.name || ''});
        const titleInvited = this.$gettext('Are you sure you wish to cancel the assignment?');
        const title = this.contractInfo.name ? titleAccepted : titleInvited;
        this.$store.commit('ModalStore/setModal', {
          component: 'cancel-assign',
          classes: ['sk-modal--new'],
          data: {
            context: this,
            title,
            data: {
              maxShortNoticeCancellation: this.computedMaxShortNoticeCancellation,
              status: this.lessThanShortNoticeCancellation ? 'paidAward' : 'award',
              ownerName: this.assignmentInfo.owner
            }
          }
        });
      },
      cancelAssignment(reason, withNotifications) {
        let params = {jobId: this.jobId, reason};
        params = withNotifications
          ? {...params, ...withNotifications}
          : this.userCanRegisterJobs
            ? {...params, withoutCharge: true}
            : params;
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/sendCancel', params).then((data) => {
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.commit('OneAssignmentStore/setJobStatus', 'cancelled');
          this.$store.commit('OneAssignmentStore/setCanceller', data.canceller_name);
          window.location.reload();
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      // BUYER CANCEL APPLIED
      openCancelRejectModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'reject-assign',
          data: {
            context: this,
            discussion: this.buyerDiscussion,
            title: this.$gettext('Are you sure that the interpreter doesn\'t match your requirements?')
          }
        });
      },
      makeReject(reason) {
        const params = {id: this.chosenDiscussionID};
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        reason ? params.reason = reason : '';
        this.$store.dispatch('OneAssignmentStore/makeReject', params).then((data) => {
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          this.handleRejectErrors(error);
        });
      },
      // SUPPLIER DECLINE BEFORE APPLY
      makeDecline() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/makeDecline', this.chosenDiscussionID).then((data) => {
          WSManager.setDataFromSockets(this, data);
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch((error) => {
          this.handleRejectErrors(error);
        });
      },
      handleRejectErrors(error) {
        this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        if (error?.status == 401) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Your rights in the Enterprise have been changed. Please try again.')
              });
            }, 0);
          });
        } else {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.dispatch('ModalStore/closeModal');
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
      },
      // SUPPLIER WITHDRAW BUTTON
      catchDecline() {
        const status = this.interDiscussionStatus;
        if (status === 'applied') {
          this.openWithdrawAppliedModal();
        } else if (status === 'accepted') {
          this.openWithdrawAcceptedModal();
        }
      },
      refetchPricingData() {
        return this.$store.commit('AssignmentFlowStore/clearFlowData');
      },
      // SUPPLIER WITHDRAW APPLIED
      openWithdrawAppliedModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'decline-int-assign',
          data: {
            title: this.$gettext('Withdraw offer'),
            context: this
          }
        });
      },
      sendDecline() {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        this.$store.dispatch('OneAssignmentStore/makeDecline', this.interDiscussion.id).then(() => {
          return this.refetchPricingData();
        }).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      },
      // SUPPLIER WITHDRAW ACCEPTED
      checkWithdrawTime() {
        if (!this.isShortNoticeWithdraw || this.$isGodmode()) {
          this.openWithdrawAcceptedModal();
        } else {
          this.openShortNoticeModal();
        }
      },
      openShortNoticeModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'disable-withdraw-assign',
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Short notice withdrawal request'),
          }
        });
      },
      openWithdrawAcceptedModal() {
        this.$store.commit('ModalStore/setModal', {
          width: 410,
          classes: ['sk-modal--new'],
          component: 'withdraw-assign',
          data: {
            title: this.$gettext('Withdraw offer'),
            orderStartTime: this.assignmentRequirements.startTime,
            context: this
          }
        });
      },
      sendWithdraw(reason) {
        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        const params = {id: this.interDiscussion.id, reason};
        this.$store.dispatch('OneAssignmentStore/makeWithdraw', params).then(() => {
          return this.refetchPricingData();
        }).then(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        }).catch(() => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
        });
      }
    }
  };
</script>
