<script>
  import {mapGetters, mapState} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userCanSeePrices',
        'userIsBusiness'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: (state) => state.storeJobObj || {},
        assignmentRequirements: (state) => state.storeJobObj?.interpretationRequirement || {},
        assignmentPrices: (state) => state.storeJobObj?.priceRequirement || {},
        interDiscussion: ({chatDiscussions}) => chatDiscussions?.discussion || {},
        buyerDiscussion: ({currentDiscussion}) => currentDiscussion?.discussion || {}
      }),
      hasEventData() { return this.eventData && this.eventData.data; },
      eventType() { return this.eventData && this.eventData.type; },
      isAutoApproveEnabled() { return this.assignmentPrices.autoApprove; },
      assignmentType() { return this.assignmentRequirements.sessionType; },
      offerData() { return this.hasEventData ? this.eventData.data : {}; },
      eventPriceInfo() { return this.hasEventData ? this.eventPriceData : {}; },
      currency() { return this.eventPriceInfo.currency || ''; },
    }
  };
</script>
